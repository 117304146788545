import type { CardMediaAssetProps, CardCommonContainerProps } from './CardAsset';
import type { CardContentProps } from './CardContentA';
import type { CardContentEProps } from './CardContentE';
import type { UrlObject } from 'url';
import type { TypographyProps } from '@mui/material';

import * as React from 'react';
import { useEditorHandlers } from './@editor';
import styled from '@emotion/styled';
import { Card as MuiCard } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from '../Link';
import { CardAsset } from './CardAsset';
import { CardContentA } from './CardContentA';
import { CardContentB } from './CardContentB';
import { CardContentC } from './CardContentC';
import { CardContentE } from './CardContentE';
import { CardContentF } from './CardContentF';
import { useAppContext } from '../../contexts/AppContext';
import { CardContentBanner } from './CardContentBanner';
import { CollectionContentA } from './CollectionContentA';
import { CollectionContentC } from './CollectionContentC';
import { CardContentFHardcoded } from './CardContentFHardcoded';
import { CollectionContentB } from './CollectionContentB';
import { useRouter } from 'next/router';

export interface CardProps extends CardMediaAssetProps, CardContentProps, CardContentEProps {
  href?: UrlObject | string;
  alt: string;
  src: string;
  width: number;
  height: number;
  hoverEffect?: boolean;
  template?: any;
  typographyOptions?: TypographyProps;
  sx?: object;
  className?: string;
  innerFlex?: string;
  alignItems?: string;
  innerFlexSize?: string;
  cardBorder?: string;
  configuration?: any;
}

const CardStyled = styled(MuiCard)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  // height: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));

const Inner = styled('div')(({ theme, innerFlex = 'column', innerAlign = 'normal' }: any) => ({
  display: 'flex',
  height: "100%",
  flexDirection: innerFlex,
  alignItems: innerAlign,
  // height: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const Card = (props: CardProps) => {
  const {
    href,
    src,
    width,
    height,
    alt,
    template,
    title,
    description,
    className,
    sx,
    typographyOptions,
    hoverEffect,
    innerFlex,
    innerFlexSize,
    cardBorder,
    configuration,
    ...other
  } = props;
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const x = width || componentOptions.component_card_width || 100;
  const y = height || componentOptions.component_card_height || 100;

  const CommonCardContainer = ({ children }: CardCommonContainerProps | any) => {
    const router = useRouter();
    const basePath = router.basePath || '';
    return (
      <CardStyled
        elevation={0}
        className={className}
        sx={sx}
        hoverEffect={hoverEffect}
        template={template}
        cardBorder={cardBorder}
        {...useEditorHandlers('Card', configuration)}
      >
        <CardActionArea component={Link} href={href || '/'} sx={{ height: '100%' }}>
          <Inner innerFlexSize={innerFlexSize} innerFlex={innerFlex} style={{ alignItems: 'center' }}>
            <CardAsset
              innerFlexSize={innerFlexSize}
              src={`${basePath}${src}`}
              width={x}
              height={y}
              alt={alt}
              {...other}
            />
            {children}
          </Inner>
        </CardActionArea>
      </CardStyled>
    );
  };

  const cardTemplates: Record<CardProps['template'], React.ReactNode> = {
    default: (
      <CommonCardContainer>
        <CardContentA title={title} description={description} {...typographyOptions} />
      </CommonCardContainer>
    ),
    simple: (
      <CommonCardContainer>
        <CardContentB title={title} {...typographyOptions} />
      </CommonCardContainer>
    ),
    extensive: (
      <CommonCardContainer>
        <CardContentC description={description} {...typographyOptions} />
      </CommonCardContainer>
    ),
    big: (
      <CommonCardContainer>
        <></>
      </CommonCardContainer>
    ),
    cnn: (
      <CommonCardContainer>
        <CardContentF title={title} description={description} {...typographyOptions} />
      </CommonCardContainer>
    ),
    banner: (
      <CommonCardContainer>
        <CardContentBanner title={title} description={description} {...typographyOptions} />
      </CommonCardContainer>
    ),
    cnnactivities: (
      <CommonCardContainer>
        <CardContentFHardcoded title={title} description={description} {...typographyOptions} />
      </CommonCardContainer>
    ),
    partial: <CardContentE width={width} height={height} {...other}></CardContentE>,
    CarouselA: (
      <CommonCardContainer>
        <CollectionContentA title={title} description={description} {...other}></CollectionContentA>
      </CommonCardContainer>
    ),
    CarouselB: (
      <CommonCardContainer>
        <CollectionContentB />
      </CommonCardContainer>
    ),
    FeatureGridA: (
      <CommonCardContainer>
        <CollectionContentC title={title} description={description} {...other}></CollectionContentC>
      </CommonCardContainer>
    ),
  };

  return <>{cardTemplates[template || 'default']}</>;
};
