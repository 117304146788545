import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useAppContext } from '../../contexts/AppContext';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  paddingBottom: `${theme.spacing(1.5)} !important`,
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(1, 1.5),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  fontSize: theme.spacing(2.25),
  fontWeight: 'bold',
  color: '#000',
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  minHeight: "45px",
  fontSize: theme.spacing(2),
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  overflow: "hidden",
  "text-overflow": "ellipsis",
  "-webkit-box-orient": "vertical"
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CollectionContentA = (props: CardContentProps) => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const { description, title, limit, className, ...other } = props;
  const body2Ref = React.useRef<HTMLInputElement | null>(null);

  return (
    <CardContentStyled className={className}>
      <CardTitle variant="caption" component="span" noWrap {...other}>
        {getText(title || '', limit || 35)}
      </CardTitle>
      <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
        {!componentOptions.component_carousel_a_hidden_description && (
          description && getText(description, limit || 75)
        )}
      </Description>
    </CardContentStyled>
  );
};
