import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Button, Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../contexts/AppContext';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  flex: 1,
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: theme.spacing(2),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardContentInfo = styled('div')(({ theme }) => ({
  flex: 1
}));


const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  fontSize: theme.spacing(3),
  fontWeight: 'bold',
  color: '#000'
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontSize: theme.spacing(2),
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  width: 'auto',
  height: 'auto',
  margin: 0,
  marginTop: theme.spacing(-3.5),
  padding: theme.spacing(0.5, 2),
  borderRadius: '100px',
  border: '1px solid #ebebeb',
  color: theme.theme_component_card_content_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_card_content_button_background || theme.palette.primary.main,
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentC = (props: any) => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const { description, title, limit, className, ...other } = props;
  const { t } = useTranslation('card_content_c');
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {title && description && (
        <CardContentStyled className={className}>
          <CardContentInfo>
            <CardTitle
              variant="caption"
              component="span"
              noWrap
              {...other}
            >
              {getText(title || "", limit || 35)}
            </CardTitle>
            {!componentOptions.component_featured_grid_a_hidden_description && (
              <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
                {description && getText(description, limit || 80)}
              </Description>
            )}
          </CardContentInfo>
          <ButtonStyled ref={buttonRef} variant="contained" disableElevation disableRipple disableFocusRipple size="small">
            {t('play_now', 'Play now')}
          </ButtonStyled>
        </CardContentStyled>
      )}
    </>
  );
};
